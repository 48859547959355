var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('form-validation-simple'), _c('form-validation-multiple'), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('form-validation-placeholder')], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('form-validation-placeholder-arguments')], 1)], 1), _c('form-validation-types'), _c('form-validation-locale'), _c('form-validation-extend')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }