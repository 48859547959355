var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Simple Form Validation"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeSimple) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("To add validation to any input filed just wrap that field in ")]), _c('code', [_vm._v("ValidationProvider")]), _c('span', [_vm._v(" component. Next, add your validation using ")]), _c('code', [_vm._v("rules")]), _c('span', [_vm._v(" prop.")])]), _c('b-card-text', [_c('span', [_vm._v("You can get errors of this field using ")]), _c('code', [_vm._v("slot.")])]), _c('validation-observer', {
    ref: "simpleRules"
  }, [_c('b-form', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', [_c('validation-provider', {
    attrs: {
      "name": "First Name",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "placeholder": "First Name"
          },
          model: {
            value: _vm.name,
            callback: function callback($$v) {
              _vm.name = $$v;
            },
            expression: "name"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', [_c('validation-provider', {
    attrs: {
      "name": "Email",
      "rules": "required|email"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "type": "email",
            "placeholder": "Email"
          },
          model: {
            value: _vm.emailValue,
            callback: function callback($$v) {
              _vm.emailValue = $$v;
            },
            expression: "emailValue"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "primary",
      "type": "submit"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.validationForm.apply(null, arguments);
      }
    }
  }, [_vm._v(" Submit ")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }