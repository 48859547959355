var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Validating Multiple Rules"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeMultiple) + " ")];
      },
      proxy: true
    }])
  }, [_c('validation-observer', {
    ref: "simpleRules"
  }, [_c('b-form', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', [_c('validation-provider', {
    attrs: {
      "name": "Name",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "placeholder": "Your Name"
          },
          model: {
            value: _vm.name,
            callback: function callback($$v) {
              _vm.name = $$v;
            },
            expression: "name"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', [_c('validation-provider', {
    attrs: {
      "name": "Email",
      "rules": "required|email"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "type": "email",
            "placeholder": "Your Email"
          },
          model: {
            value: _vm.emailValue,
            callback: function callback($$v) {
              _vm.emailValue = $$v;
            },
            expression: "emailValue"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', [_c('validation-provider', {
    attrs: {
      "name": "Password",
      "vid": "Password",
      "rules": "required|password"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('b-form-input', {
          attrs: {
            "type": "password",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Your Password"
          },
          model: {
            value: _vm.PasswordValue,
            callback: function callback($$v) {
              _vm.PasswordValue = $$v;
            },
            expression: "PasswordValue"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', [_c('validation-provider', {
    attrs: {
      "name": "Password Confirm",
      "rules": "required|confirmed:Password"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "type": "password",
            "placeholder": "Confirm Password"
          },
          model: {
            value: _vm.passwordCon,
            callback: function callback($$v) {
              _vm.passwordCon = $$v;
            },
            expression: "passwordCon"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', [_c('b-button', {
    attrs: {
      "variant": "primary",
      "type": "submit"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.validationForm.apply(null, arguments);
      }
    }
  }, [_vm._v(" Submit ")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }